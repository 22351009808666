import { LINKS } from '@utils/constants';
import { ICareer } from '@utils/types';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function CareerTemplate({
	data,
	pageContext: {
		breadcrumb: { location, crumbs },
	},
}: {
	data: {
		site: { siteMetadata: { siteUrl: string } };
		markdownRemark: {
			frontmatter: ICareer;
			html: string;
		};
		banner: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
	};
	pageContext: {
		breadcrumb: {
			location: string;
			crumbs: Breadcrumb[];
		};
	};
}) {
	const { t } = useTranslation();

	const { markdownRemark, banner, site } = data;
	if (!markdownRemark) return null;

	const { frontmatter, html } = markdownRemark;

	const avatar = getImage(frontmatter.image.childImageSharp.gatsbyImageData);
	const self = site?.siteMetadata?.siteUrl + location;

	return (
		<Page>
			<SEO
				title={`${frontmatter.title} | ${t("_Careers")} | Realis Simulation`}
				description=""
			/>

			<Banner
				title={frontmatter.title}
				image={{
					src: banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection layout="wide">
				<div className="profile-wrapper">
					<div className="col xl3 offset-xl1 m4 offset-m0 s12 s-spacer career__aside">
						{avatar && (
							<div className="profile-avatar">
								<div className="image-background">
									<GatsbyImage
										image={avatar}
										alt={frontmatter.title}
									/>
								</div>
							</div>
						)}
						<div className="profile-opportunity">
							<Button to={LINKS.ABOUT_CAREERS} type="more">
								{t("_OtherVacancies")}
							</Button>
						</div>
					</div>
					<div className="col xl6 offset-xl1 l7 offset-l0 m8 s12 s-spacer career__content">
						<header className="career__header">
							<h1 className="profile-name">
								{frontmatter.title}
							</h1>
							<span className="profile-job">
								{frontmatter.summary}
							</span>
						</header>
						<div
							className="profile-bio"
							dangerouslySetInnerHTML={{ __html: html }}
						/>

						<aside className="share__icons">
							<span>{t("_ShareThisJob")}</span>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://www.linkedin.com/shareArticle?mini=true&url=${self}&title=${frontmatter.title}&summary=${frontmatter.summary}`}
								className="social-icon__item social-icon--linkedin"></a>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://twitter.com/share?url=${self}&title=${frontmatter.title}`}
								className="social-icon__item social-icon--twitter"></a>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://www.facebook.com/sharer/sharer.php?u=${self}`}
								className="social-icon__item social-icon--facebook"></a>
						</aside>
					</div>
				</div>
			</PageSection>
		</Page>
	);
}

export const pageQuery = graphql`
	query ($language: String!, $slug: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		locales: allLocale(
			filter: { ns: { in: ["_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(
      		fields: { language: { eq: $language }, slug: { eq: $slug } }
		) {
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
			}
			html
		}

		banner: file(relativePath: { eq: "content/about-us/careers/Realis-Simulation_Careers.jpg" }) {
			...imageBreaker
		}
	}
`;
